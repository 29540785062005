import React from "react";
import { Footer, Header } from "components/base";
import { Outlet } from "react-router-dom";
import UpperHeader from "components/base/Header/UpperHeader";
import LowerHeader from "components/base/Header/LowerHeader";
import { TranslationProvider } from 'hooks/Language';

function Index() {
  return (
    <div className="main-wrapper bg-white">
      <TranslationProvider>
        <UpperHeader divClass="bg-lightgrey" />
        <Header divClass="bg-greylight" />
        <LowerHeader />

        <Outlet />
        <Footer divClass="bg-white" />
      </TranslationProvider>
    </div>
  );
}

export default Index;
