import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Cart from "templates/Cart";
import Location from "templates/Location";

const trendProduct = [
  {
    imageUrl: "p.png",
    title: "Almonds Lightly Salted ",
    price: "29",
    weight: "300 gm",
  },
  {
    imageUrl: "p.png",
    title: "Assorted Donuts Salted ",
    price: "79",
    weight: "5 Kg",
  },
  {
    imageUrl: "p.png",
    title: "Natures Own 100% Wheat ",
    price: "29",
    weight: "500 gm",
  },
  {
    imageUrl: "p.png",
    title: "Blue Diamond Almonds ",
    price: "40",
    weight: "400 gm",
  },
];

function Header() {
  const [locationOpen, setLocationOpen] = useState(false);
  const [openCart, setOpenCart] = useState(false);

  return (
    <div
      className={`header-wrapper pt-4 pb-4 z-index-5 ovh d-none d-lg-block bg-white`}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 d-flex">
            <Link to="/">
              <img
                src={require("assets/images/png/logotxt.png")}
                alt="logo"
                className="logo w-125 pt-2"
              />
            </Link>
            <div className="header-search ms-auto me-2 d-flex">
              <Button
                // onClick={() => {
                //   setLocationOpen(true);
                // }}
                className="location me-3 bg-transparent border-0"
              >
                <span className="fw-600 font-xssss text-grey-600">
                  Delivery to
                </span>
                <i className="feather-map-pin text-grey-600"></i>
                <h4 className="fw-600 font-xssss mt-0 text-grey-900 mb-0 ls-0">
                  Downtown Berlin
                </h4>
              </Button>
              <Location isOpen={locationOpen} setIsOpen={setLocationOpen} />
              <div className="form-group mb-0 icon-input d-none d-xl-block me-2">
                <i className="feather-search font-sm text-grey-400"></i>
                <input
                  type="text"
                  placeholder="Start typing to search.."
                  className="lh-38 pt-2 pb-2 ps-5 pe-3 font-xssss fw-500 rounded-xl posr"
                />
              </div>
              <a href="#" className="nav-icon">
                <span className="dot-count bg-warning"></span>
                <i className="feather-bell text-grey-500"></i>
              </a>
              <a href="/Saved" className="nav-icon">
                <i className="feather-heart text-grey-500"></i>
              </a>
              <Button
                // onClick={() => {
                //   setOpenCart(true);
                // }}
                className="nav-icon bg-transparent border-0 mt-n1 posr"
              >
                <i className="feather-shopping-bag text-grey-500"></i>
              </Button>
              <Cart isOpen={openCart} setIsOpen={setOpenCart} />
              <Link to="/dashboard" className="nav-icon p-0">
                <img
                  src="https://via.placeholder.com/50x50.png"
                  alt="user"
                  className="w-40 mt-1 rounded-circle"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
