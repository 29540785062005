import { combineReducers } from 'redux';
import { PURGE } from 'redux-persist';

import categoryReducer from './categories/reducer';
import productReducer from './products/reducer';
import bannerReducer from './banners/reducer';

const appReducer = combineReducers({
  category: categoryReducer,
  product: productReducer,
  banner: bannerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
