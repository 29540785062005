import { useTranslation } from "hooks/Language";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Categories from "templates/Categories";

function LowerHeader() {
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const { translate } = useTranslation();

  return (
    <div className="lower-header pt-0 pb-0 shadow-xss bg-white d-none d-lg-block">
      <div className="container">
        <div className="container-wrap posr">
          <div className="row">
            <div className="col-lg-3">
              <div className="dropdown dropdown-right border-0">
                <Button
                  onClick={() => {
                    setCategoriesOpen(true);
                  }}
                  className="bg-transparent btn8 border-0 rounded-0 dropdown-toggle"
                >
                  <i className="feather-align-left"></i> All Categories
                </Button>

                <Categories
                  isOpen={categoriesOpen}
                  setIsOpen={setCategoriesOpen}
                />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="navbar navbar-expand-lg p-0">
                <div className="navbar-collapse collapse show" id="main_nav">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#main_nav"
                    aria-expanded="true"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <ul className="navbar-nav">
                    <li className="nav-item nav-item-toggle">
                      <a className="nav-items" href="/">
                        {translate('home')}
                      </a>
                    </li>
                    <li className="nav-item nav-item-toggle">
                      <a className="nav-items" href="/products">
                        {translate('shop')}
                      </a>
                    </li>
                    <li className="nav-item nav-item-toggle">
                      <a className="nav-items" href="/about">
                        {translate('about')}
                      </a>
                    </li>
                    <li className="nav-item nav-item-toggle">
                      <a className="nav-items" href="/contact">
                        {translate('contact')}
                      </a>
                    </li>
                  </ul>
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item text-grey-500 fw-500 font-xssss">
                      Need help? Call Us :{" "}
                      <a href="tel:+41 78645352" className="fw-700 text-current">
                        +41 79 864 53 52
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LowerHeader;
