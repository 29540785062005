import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Navbar, Nav } from 'react-bootstrap';
import { loadCategories } from 'store/categories';

function Index({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCategories());
  }, [dispatch]);

  const { categories } = useSelector((state) => state.category);

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={isOpen}
      className='left'
    >
      <Button
        onClick={() => {
          setIsOpen(false);
        }}
        className='btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss'
      ></Button>
      <Modal.Body className='text-center pt-3 m-0'>
        <div className='card w-100 p-2 pb-0 border-0 text-start'>
          <h4 className='fw-700 font-lg text-grey-900 text-start mb-3 mt-n2 d-block ls-0'>
            {' '}
            Categories
          </h4>
          <Navbar expand='lg' className='dropdown-navbar'>
            <Navbar id='basic-navbar-nav' className='w-100 d-block'>
              <Nav>
                {categories?.map((item, key) => (
                  <Nav.Link href='#Fruits'>{item?.name}</Nav.Link>
                ))}
              </Nav>
            </Navbar>
          </Navbar>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Index;
