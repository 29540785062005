import axios from 'config/axios';
import * as types from './types';

export const loadCategories = () => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOAD_CATEGORIES_REQUEST });

    const { data } = await axios.get('categories');
    dispatch({
      type: types.LOAD_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.LOAD_CATEGORIES_FAIL,
      payload: error,
    });
  }
};
