import { Route, Routes } from 'react-router-dom';
import { LazyPage } from 'components/base';
import NotFound from 'pages/NotFound';
import App from 'layouts';
import * as routes from 'constants/routes';

function Router() {
  return (
    <Routes>
      <Route path={routes.HOME} element={<App />}>
        <Route index element={<LazyPage page='Index/' />} />

        <Route path={routes.ABOUT} element={<LazyPage page='About' />} />
        <Route
          path={routes.CONTACTUS}
          element={<LazyPage page='ContactUs' />}
        />
        <Route path={routes.PRODUCTS} element={<LazyPage page='Products' />} />
        <Route
          path={routes.PRODUCT}
          element={<LazyPage page='Products/Detail' />}
        />

        <Route path={routes.CHECKOUT} element={<LazyPage page='Checkout' />} />
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default Router;
