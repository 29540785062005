import React from 'react';
import { Modal, Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';

function Index({ isOpen, setIsOpen }) {
  const trendProduct = [
    {
      imageUrl: 'g-11.png',
      title: 'Almonds Lightly Salted ',
      price: '29',
      weight: '300 gm',
    },
    {
      imageUrl: 'g-12.png',
      title: 'Assorted Donuts Salted ',
      price: '79',
      weight: '5 Kg',
    },
    {
      imageUrl: 'g-14.png',
      title: 'Natures Own 100% Wheat ',
      price: '29',
      weight: '500 gm',
    },
    {
      imageUrl: 'g-19.png',
      title: 'Blue Diamond Almonds ',
      price: '40',
      weight: '400 gm',
    },
  ];

  return (
    <Modal
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={isOpen}
      className='right'
    >
      <Button
        onClick={() => {
          setIsOpen(false);
        }}
        className='btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss'
      ></Button>
      <Modal.Body className='text-center pt-3 m-0 pb-3'>
        <div className='card w-100 p-2 pb-0 border-0 text-start'>
          <h4 className='fw-700 font-lg text-grey-900 text-start mb-4 mt-n2 d-block'>
            {' '}
            Cart
          </h4>
          {trendProduct.map((value, index) => (
            <div key={index} className='row mb-3'>
              <div className='col-md-5 col-xs-5'>
                <a href='/' className='d-block text-center'>
                  <img
                    src={require(`assets/images/png/${value.imageUrl}`)}
                    alt='product'
                    className='w-100 d-inline-block pt-3 pb-3 bg-greylight rounded-6'
                  />
                </a>
              </div>
              <div className='col-md-7 col-xs-7 ps-0'>
                <span className='ms-auto text-grey-500 fw-500 lh-1 font-xsssss mt-0 w-100 mb-2'>
                  {value.weight}
                </span>
                <a
                  href='/'
                  className='text-grey-900 fw-600 font-xsss lh-22 d-block ls-0 mb-2 pe-lg-4'
                >
                  {value.title}
                </a>
                <h6 className='font-xs ls-3 fw-700 text-current float-start mt-1'>
                  <span className='font-xsssss text-grey-500'>$</span>
                  {value.price}{' '}
                </h6>
                <div className='cart-count float-end '>
                  <div className='number'>
                    <span className='minus'>-</span>
                    <input
                      type='text'
                      className='open-font ms-1 me-1'
                      defaultValue='1'
                    />
                    <span className='plus'>+</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='card w-100 p-3 pt-4 border-0 text-start mt-auto'>
          <div className='row'>
            <div className='col-lg-12'>
              <h4 className='text-grey-900 font-xssss fw-600 mb-2 d-flex'>
                Subtotal <span className='ms-auto text-grey-500'>$ 59.99</span>
              </h4>
              <h4 className='text-grey-900 font-xssss fw-600 mb-3 d-flex'>
                Tax <span className='ms-auto text-grey-500'>$ 0.99</span>
              </h4>
              <h4 className='text-grey-900 font-xss fw-600 mb-3 d-flex'>
                Total <span className='ms-auto'>$ 60.99</span>
              </h4>
              <h5 className='bg-greylight p-4 rounded-6 mt-3 mb-3 w-100 fw-600 text-grey-500 font-xssss d-flex'>
                Apply Promo Code :{' '}
                <span className='ms-auto fw-700 text-grey-900'>2 Promos</span>
              </h5>
            </div>
          </div>

          <a
            href='/Checkout'
            className='w-100 bg-green text-white rounded-6 text-center btn'
            id='checkout'
          >
            Checkout
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Index;
