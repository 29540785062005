import React, { createContext, useContext, useState, useEffect } from 'react';
import en from './en.json';
import nl from './nl.json';

const TranslationContext = createContext();

export const useTranslation = () => useContext(TranslationContext);

// Define translations object including English and Dutch translations
const translations = {
    en: en,
    nl: nl
};

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
        const storedLanguage = localStorage.getItem('language');
        return storedLanguage || 'en';
    });

    const translate = (key) => {
        return translations[language][key] || key;
    };

    const changeLanguage = (selectedLanguage) => {
        setLanguage(selectedLanguage);
        localStorage.setItem('language', selectedLanguage);
    };

    useEffect(() => {
        const storedLanguage = localStorage.getItem('language');
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    return (
        <TranslationContext.Provider value={{ translate, changeLanguage, language }}>
            {children}
        </TranslationContext.Provider>
    );
};
