import React from 'react';
import AppProvider from 'providers';
import { Router } from 'router';

function App() {
  return (
    <React.Fragment>
      <AppProvider>
        <Router />
      </AppProvider>
    </React.Fragment>
  );
}

export default App;
