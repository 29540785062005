import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function Index({ isOpen, setIsOpen }) {
  return (
    <div>
      <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={isOpen}
      >
        <Button
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className='btn-close z-index-5 posa right-0 top-0 mt-3 me-3 font-xss'
        ></Button>
        <Modal.Body className='text-center pt-4'>
          <i className='feather-map-pin bg-greylight text-grey-900 btn-round-xxxl font-xl text-center rounded-6'></i>
          <h2 className='font-xss fw-700 text-grey-700 mt-4'>
            Select your location
          </h2>
          <p className='text-grey-500 font-xsssss mt-1'>
            Implementation of technologies to store <br /> unchange data based
            on specific
          </p>
          <div className='inner-addon left-addon'>
            <input
              type='text'
              className='form-control ps-5 font-xssss border-sizelg rounded-6 bg-color-none fw-600 border text-grey-500'
              defaultValue='675 Camac Street Down'
            />
            <i className='ti-location-arrow text-current ps-3 font-xss posa left-15 mt-3'></i>
          </div>
          <Button className='border-0 btn rounded-6 w-100 lh-2 d-block p-3 mt-0 mb-2 text-white bg-current font-xssss text-uppercase fw-600 ls-3'>
            Current Location{' '}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Index;
