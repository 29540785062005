import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useStore } from 'store';
import { ErrorBoundary, Loading } from 'components/base';
import { name, lang } from 'config/env';

function AppProvider({ children }) {
  const { store } = useStore();

  return (
    <ErrorBoundary>
      {store ? (
        <Provider store={store}>
          <HelmetProvider>
            <Helmet>
              <html lang={lang} />
              <title>{name}</title>
            </Helmet>
            <BrowserRouter>{children}</BrowserRouter>
          </HelmetProvider>
        </Provider>
      ) : (
        <Loading />
      )}
    </ErrorBoundary>
  );
}

export default AppProvider;
