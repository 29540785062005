import * as types from './types';

const initialState = {
  loading: false,
  banners: [],
  errors: {},
};

const bannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_BANNERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_BANNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        banners: action.payload,
      };

    case types.LOAD_BANNERS_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default bannerReducer;
