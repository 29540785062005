import React from 'react';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from 'store/reducer';
import { createAxios } from 'config/axios';

const createConfigureStore = async () => {
  const store = createStore(reducers, compose(applyMiddleware(thunk, promise)));

  createAxios(store);
  return { store };
};

export function useStore() {
  const alive = React.useRef(true);

  const [store, setStore] = React.useState();

  React.useEffect(() => {
    const configStore = async () => {
      try {
        const { store } = await createConfigureStore();

        if (alive.current) {
          setStore(store);
        }
      } catch (error) {
        // handle error
      }
    };

    configStore();
    setTimeout(() => {
      return () => {
        alive.current = false;
      };
    }, 3000);
  }, []);

  /**
   * Check the store created successfully
   * @type {boolean}
   */
  const isReady = React.useMemo(() => {
    return store !== null;
  }, [store]);

  return { store, isReady };
}
export default createConfigureStore;
