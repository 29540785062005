import React from 'react';
import Lottie from 'lottie-react';
import animation from 'assets/animations/loader.json';

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className='main-wrapper bg-white'
    >
      <Lottie style={{ width: 200, height: 200 }} animationData={animation} />
    </div>
  );
}

export default Loading;
