import * as types from './types';

const initialState = {
  loading: false,
  categories: [],
  errors: {},
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case types.LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };

    case types.LOAD_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
};

export default categoryReducer;
