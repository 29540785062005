import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'hooks/Language';


function UpperHeader() {
  const { translate, changeLanguage, language } = useTranslation();

  return (
    <div className={`upper-header pt-2 pb-2 d-none d-lg-block`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ul className="nav">
              <li className="nav-item">
                <Link className=" ps-0" to="/about">
                  {translate('about')}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="#">
                  {translate('myAccount')}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="#">Wishlist</Link>
              </li>
              <li className="nav-item">
                <Link to="#">Order Tracking</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 text-end">
            <ul className="navbar-nav float-end">
              <li className="nav-item nav-item-toggle active dropdown">
                {language === 'en' && (
                  <li>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      onClick={() => changeLanguage('nl')}
                    >
                      Dutch
                    </a>
                  </li>
                )}
                {language === 'nl' && (
                  <li>
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      onClick={() => changeLanguage('en')}
                    >
                      English
                    </a>
                  </li>
                )}
              </li>

              <li className="nav-item nav-item-toggle active dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  CHF
                </a>
                <ul className="dropdown-menu border-0 shadow-xss">
                  <li>
                    <a className="dropdown-item" href="/">
                      {" "}
                      USD
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      {" "}
                      AUD{" "}
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/">
                      {" "}
                      REA{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpperHeader;
